import { container } from 'assets/jss/material-kit-react.jsx';
import imagesStyles from 'assets/jss/material-kit-react/imagesStyles.jsx';

const sharedStyle = {
  sections: {
    padding: '10px 0',
  },
  centerContainer: {
    textAlign: 'center',
  },
  container,
  title: {
    fontSize: '2rem',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  space50: {
    height: '50px',
    display: 'block',
  },
  space70: {
    height: '70px',
    display: 'block',
  },
  icons: {
    width: '50px',
    height: '50px',
    color: '#000000',
  },
  verticleLine: {
    borderLeft: '3px solid green',
    height: '20px',
    position: 'absolute',
    left: '50%',
    marginLeft: '-3px',
  },

  horizontalLine: {
    display: 'block',
    height: '1px',
    border: '0',
    borderTop: '1px solid #ccc',
    margin: '1em 0',
    padding: '0',
  },
  ...imagesStyles,
};

export default sharedStyle;
