/*eslint-disable*/
import React from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// nodejs library that concatenates classes
import classNames from "classnames"
import { Link } from "gatsby"

import { List, ListItem, withStyles } from "@material-ui/core"

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite"

import footerStyle from "assets/jss/material-kit-react/components/footerStyle.jsx"

function Footer({ ...props }) {
  const { classes, whiteFont } = props
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont,
  })
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont,
  })
  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <Link to={"/"} className={classes.listItem}>
              <ListItem className={classes.inlineBlock}>
                <a className={classes.block} target="_blank">
                  Home
                </a>
              </ListItem>
            </Link>
            <Link to="/services" className={classes.listItem}>
              <ListItem className={classes.inlineBlock}>
                <a className={classes.block} target="_blank">
                  Services
                </a>
              </ListItem>
            </Link>
            <Link to="/contact-us" className={classes.listItem}>
              <ListItem className={classes.inlineBlock}>
                <a className={classes.block} target="_blank">
                  Contact Us
                </a>
              </ListItem>
            </Link>
          </List>
        </div>
        <div className={classes.right}>
          Developed in house by LR-XS &copy;{" "}
          {1900 + new Date().getYear()}
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool,
}

export default withStyles(footerStyle)(Footer)
